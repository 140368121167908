import axios from "../axios";
import {OffersProviderDataMC} from "../store/actions/vehicles";

interface Benefit {
    icon: string;
    text: string;
}

interface DocumentStepItem {
    icon: string;
    caption: string;
}

export interface OfferDetails {
    benefits: [Benefit];
    documents: [DocumentStepItem];
    nextSteps: [DocumentStepItem];
}

interface Card {
    logo?: string;
    logoType?: string;
    offerDetails: OfferDetails;
}

interface NextStepText {
    body: [];
    header: string;
}

interface FormItem {
    name: string;
    scenario: string;
    excludeFields: string[];
}

interface Data {
    nextStepText: NextStepText;
    details: string[];
    card: Card;
    forms?: FormItem[]
}

export interface ProviderData {

    locationId: string;
    data: Data
    ;
}

export class ProviderData {
    static async get(data : OffersProviderDataMC[]) {
        const requestInterceptorLF = axios.interceptors.request.use(
            async config => {
                config.baseURL = process.env.REACT_APP_LONG_FORM_API_URL;

                config.headers || (config.headers = {});
                config.headers['Authorization'] = 'Bearer ' + process.env.REACT_APP_LONG_FORM_ACCESS_TOKEN;

                return config
            },
            error => Promise.reject(error)
        )

        const res = await axios.post('/carwiser/offer-providers' , {matchingClients : data});

        axios.interceptors.request.eject(requestInterceptorLF);
        return res?.data?.offerProviders
    }
}